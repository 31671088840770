<div class="wrapper">
    <div class="d-flex align-items-center justify-content-between">
        <p class="h3">Ticket History</p>
        <form [formGroup]="filters" class="d-flex align-items-center justify-content-end mr-4" style="float: right;">
            <div class="mr-5">
                <config-select name="Status" formControlName="status"
                    [options]="['Open', 'Reopened','Resolved', 'Closed', 'Duplicate']" default="All"
                    (change)="getIssues()"></config-select>
            </div>
            <div class="mr-5">
                <config-select name="Priority" formControlName="priority"
                    [options]="['Critical', 'High','Normal', 'Low', 'Very Low']" default="All"
                    (change)="getIssues()"></config-select>
            </div>
            <div>
                <label>Duration</label>
                <div class="d-flex align-items-center border rounded" style="width: 200px; height: 30px;">
                    <mat-date-range-input [rangePicker]="picker" class="mt-1">
                        <input matStartDate formControlName="start_date" placeholder="Start date" class="mt-1 ms-3"
                            style="margin-left: 13px;">
                        <input matEndDate formControlName="end_date" placeholder="End date" (dateInput)="getIssues()"
                            style="margin-bottom: 0.15rem;">
                    </mat-date-range-input>
                    <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>  -->
                    <mat-datepicker-toggle matSuffix [for]="picker" (click)="clearDates()"
                        style="background-color: none;"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>

                    <mat-error *ngIf="filters.controls.start_date.hasError('matStartDateInvalid')">Invalid start
                        date</mat-error>
                    <mat-error *ngIf="filters.controls.end_date .hasError('matEndDateInvalid')">Invalid end
                        date</mat-error>
                </div>
            </div>
        </form>
    </div>

    <div class="d-flex mt-4">
        <div class="shadow mx-2 d-flex align-items-center" style="flex: 1; min-height: 5rem;"
            *ngFor="let item of topCards">
            <div *ngIf="!loading" class="d-flex justify-content-between align-items-center position-relative p-1"
                style="height: 100%; width: 100%;">
                <img [src]="item.icon" [alt]="item.title" [ngStyle]="{'height': item.height, 'width': item.width}"
                    style="margin-left: 10px;">
                <p class="h3 m-0 mt-1" style="text-align: middle;">{{item.value}}</p>
                <p class="mt-5" style="bottom: 0;right: 0; text-align: end !important;">{{item.title}}</p>
            </div>
            <div *ngIf="loading" style="width: 95%; height: 90%;">
                <cosgrid-custom-loader [cardLoading]="true"></cosgrid-custom-loader>
            </div>
        </div>
    </div>
    <div class="rows mt-3">
        <div class="box" style="flex: 1;">
            <div class="title-container" *ngIf="!loading">
                <h4>Ticket Status</h4>
            </div>
            <div class="graph-box" *ngIf="!loading">
                <pie-chart [data]="pieCharts.status.data" [legendData]="pieCharts.status.label"
                    defaultLabelValue="key+value" [legend]="false" chartType='doughnut' innerRadius="40%" label="5"
                    radius="85%"></pie-chart>
                <!-- <cosgrid-echarts-barchart *ngIf="!loading" title="" [data]="data['rule']['data']"
                    [value]="data['rule']['value']" chartType="bar" xAxisRotate="45"></cosgrid-echarts-barchart> -->
            </div>
            <cosgrid-custom-loader [show]="loading" [cardLoading]="true"></cosgrid-custom-loader>
            <!-- <app-error [errHeading]="errorMessage.graph.errHeading" [errMessage]="errorMessage.graph.errMessage"
                [errRequest]="errorMessage.graph.errRequest" [refresh]="errorMessage.graph.refresh"
                *ngIf="error.graphError"></app-error> -->
        </div>
        <div class="box" style="flex: 1;">
            <div class="title-container" *ngIf="!loading">
                <h4>Ticket Priority</h4>
            </div>
            <div class="graph-box" *ngIf="!loading">
                <pie-chart [data]="pieCharts.priority.data" [legendData]="pieCharts.priority.label" defaultLabelValue="key+
                    value" [legend]="false" [colors]="['#ff560b', '#ffa608', '#0078be', '#6801af']"
                    radius="85%"></pie-chart>
            </div>
            <cosgrid-custom-loader [show]="loading" [cardLoading]="true"></cosgrid-custom-loader>
            <!-- <app-error [errHeading]="errorMessage.graph.errHeading" [errMessage]="errorMessage.graph.errMessage"
                [errRequest]="errorMessage.graph.errRequest" [refresh]="errorMessage.graph.refresh"
                *ngIf="error.graphError"></app-error> -->
        </div>
        <div class="box" style="flex: 2;">
            <div class="title-container" *ngIf="!loading">
                <h4>Tickets Per day</h4>
            </div>
            <div class="graph-box" *ngIf="!loading">
                <app-echart [recordDuration]="xAxisTimestamp" [title]="" [names]="['Ticket-per-day']"
                    [data]="barChart.dateWise.value" [timestamp]="barChart.dateWise.timestamp" chartType="bar"
                    [legend]="false" yAxisType="no-suffix" title=""
                    [informationBox]="{min: false, max: false, average: false, current: false}"></app-echart>
            </div>
            <cosgrid-custom-loader [show]="loading" [cardLoading]="true"></cosgrid-custom-loader>
            <!-- <app-error [errHeading]="errorMessage.graph.errHeading" [errMessage]="errorMessage.graph.errMessage"
                [errRequest]="errorMessage.graph.errRequest" [refresh]="errorMessage.graph.refresh"
                *ngIf="error.graphError"></app-error> -->
        </div>
    </div>

    <cosgrid-table [rows]="rows" [rowProps]="rowProps" [refreshLoading]="loading" [tableProps]="tableProps"
        [isConfig]="true" (view)="viewFollowUp($event)"></cosgrid-table>
</div>