import { User } from 'app/core/models/user';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CosgridAppStore } from 'app/store/app.store';
import * as _ from 'lodash';
import { AlertService, SupportService } from 'app/shared/services/misc';
import { CustomerListService, DeviceListService } from 'app/shared/services/comp';
import { skipWhile } from 'rxjs/operators';
import { ValidateForm } from 'app/shared/decorators/validateForm.decorator';
import { UserService } from 'app/shared/services/core';

@Component({
    selector: 'cosgrid-ticket-raise',
    templateUrl: './ticket-raise.component.html',
    styleUrls: ['./ticket-raise.component.scss'],
})
export class TicketRaiseComponent implements OnInit {
    currentTenantName: string = this.customerListService.getTenantName();
    loading: boolean = false;
    devices = {
        sdwan: [],
        mza: []
    };
    product: string = ''
    ticketRaiseForm: UntypedFormGroup = this.fb.group({
        product: [this.product, Validators.required],
        ticketId: Date.now().toString(36) + Math.random().toString(36).substr(2, 9),
        tenant_name: this.currentTenantName,
        title: ['', Validators.required],
        device: [[], Validators.required],
        priority: [1, Validators.required],
        mode_contact: ['Email'],
        description: [''],
        queue: 1,
        submitter_email: ''
    });

    constructor(
        private fb: UntypedFormBuilder,
        private appStore: Store<CosgridAppStore>,
        private supportService: SupportService,
        private alert: AlertService,
        private customerListService: CustomerListService,
        private userService: UserService,
    ) { }

    async ngOnInit() {
        this.ticketRaiseForm.get('submitter_email').setValue(await this.userService.getUserEmail())
        this.appStore.select((state) => state.settingSlice).subscribe((value) => {
            if (!value.setting.vpnDashboard)
                this.product = 'Reflex SD-WAN'
            else
                this.product = 'MicroZAccess'

            this.ticketRaiseForm.get('product').setValue(this.product)

        })
        this.getAllDevices();
    }

    getAllDevices() {
        let selectedNetwork;
        this.appStore
            .select((slices) => slices.networkSlice.selectedNetwork)
            .subscribe((res) => {
                if (res) {
                    // getdevicelist call is in changeNetwork() cuz network cannot be changed except here
                    // device selection deleted when the user literally toggles the selection and not when the network value changes
                    selectedNetwork = res;
                }
            });
        this.supportService.getDeviceList(
            {
                tenant_id: this.customerListService.getTenantId(),
                network_id: selectedNetwork.id,
                type: 'endpoint'
            }).subscribe(res => {
                this.devices.mza = res

            })
        this.supportService.getDeviceList(
            {
                tenant_id: this.customerListService.getTenantId(),
                network_id: selectedNetwork.id,
                type: ''
            }).subscribe(res => {
                this.devices.sdwan = res

            })
        // this.deviceListService.getDeviceList({
        //     tenant_id: this.customerListService.getTenantId(),
        //     network_id: selectedNetwork.id,
        // }).subscribe((res: any) => {
        //     console.log(res);
        //     this.devices.sdwan = res
        // })
    }
    @ValidateForm('ticketRaiseForm')
    submitForm() {
        this.loading = true;
        this.supportService.postForm(this.ticketRaiseForm.value).subscribe(
            () => {
                this.alert.swalSuccess('Your Ticket Has been Successfully submitted');
                this.loading = false;
                this.ticketRaiseForm.reset()
            },
            (err) => {
                this.loading = false;
            },
        );
    }
}
