import { filter } from 'rxjs';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IRowConfig, tableProps } from 'app/shared/services/comp';
import { SupportService } from 'app/shared/services/misc';
import { TimeformatPipe } from 'app/shared/pipes/timeformat.pipe';

@Component({
  selector: 'cosgrid-follow-up',
  templateUrl: './follow-up.component.html',
  styleUrls: ['./follow-up.component.scss']
})
export class FollowUpComponent implements OnInit {

  followups: any;
  tableProps: tableProps = {
    search: false,
    download: false,
    sort: false,
    refresh: false
  }
  loading: boolean = true

  rowProps: Array<IRowConfig> = [
    { header: 'Title', key: 'title', type: "string" },
    { header: 'Comment', key: 'comment', type: 'string' },
    { header: 'Date', key: 'formattedDate', type: 'string' },
    { header: 'Time', key: 'formattedTime', type: 'string' },
    {
      header: 'Status', key: 'status_display', type: 'string', dataStyle: (row, key) => ({
        'background-color':
          row[key].toLowerCase() === 'open'
            ? '#34b000' :
            row[key].toLowerCase() === 'closed'
              ? '#ff560b' : row[key].toLowerCase() === 'resolved'
                ? '#0078be' : row[key].toLowerCase() === 'duplicate'
                  ? '#6801af' : '#6801af',
        padding: '5px 10px',
        'border-radius': '5px',
        color: '#fff',
        width: '80px',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        'font-size': '14px',
      })
    },
  ]
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<FollowUpComponent>,
    private supportService: SupportService,
    private timeFormatPipe: TimeformatPipe,
  ) { }

  ngOnInit(): void {
    this.getFollowUps()
  }

  getFollowUps() {
    this.loading = true
    this.supportService.getFollowUps(this.data).subscribe((res) => {
      this.followups = res
      console.log(this.followups.followups)
      this.followups.followups.map((row, index) => {
        console.log(row.status_display)
        row.formattedDate = this.timeFormatPipe.transform(row.date, 'DD/MM/yyyy')
        row.formattedTime = this.timeFormatPipe.transform(row.date, 'hh:mm')
        if (row.status_display == null && index == 0)
          row.status_display = 'Open'
        else if (row.status_display == null)
          row.status_display = this.followups.followups[index - 1].status_display
        else
          row.status_display = row.status_display
      })
      this.loading = false
    })
  }

}
