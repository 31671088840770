import { ConfirmDialog } from '../../components/confirm.dialog/confirm.dialog.component';
import { AlertService } from '../../services/misc';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
declare var $: any;

@Component({
    selector: 'cosgrid-table-actions',
    templateUrl: './table-actions.component.html',
    styleUrls: ['./table-actions.component.scss'],
})
export class TableActionsComponent implements OnInit {
    @Input() value = false;
    @Input() isDisabled = false;
    @Output() view = new EventEmitter<any>();
    @Output() toggled = new EventEmitter<any>();
    @Output() edit = new EventEmitter<any>();
    @Output() delete = new EventEmitter<any>();

    displayToggle = false;
    needEdit = false;
    needDelete = false;
    needView = false;
    constructor(private dialog: MatDialog, private alertService: AlertService) { }

    ngOnInit() {
        // to find whether parent componenet needs toggle or not,
        if (this.toggled.observers.length) {
            this.displayToggle = true;
        }
        if (this.edit.observers.length) {
            this.needEdit = true;
        }
        if (this.delete.observers.length) {
            this.needDelete = true;
        }

        if (this.view.observers.length) {
            this.needView = true;
        }
    }
    ngAfterViewInit() {
        $('[data-toggle="tooltip"]').tooltip();
    }

    toggleValueChanged(event: boolean) {
        this.toggled.emit(event);
    }

    editClicked(ev) {
        ev.stopPropagation();
        this.edit.emit();
    }

    deleteClicked(ev) {
        ev.stopPropagation();
        const dialogRef = this.dialog.open(ConfirmDialog);
        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                this.delete.emit();
            }
        });
    }

    viewClicked(ev) {
        ev.stopPropagation();
        this.view.emit();
    }
}
