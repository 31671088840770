<div class="actionContainer" [class.disabled]="isDisabled">
  <ng-switchery matTooltip="Toggle Enable/Disable" *ngIf="displayToggle" [ngModel]="value" [attr.disabled]="isDisabled"
    (ngModelChange)="isDisabled ? $event.preventDefault() : toggleValueChanged($event)" size="switchery-sm"
    name="switchery-check">
  </ng-switchery>

  <i *ngIf="needView" matTooltip="View" [class.disabled]="isDisabled" (click)="!isDisabled && viewClicked($event)"
    class="fa fa-eye view" aria-hidden="true">
  </i>

  <i *ngIf="needEdit" matTooltip="Edit" [class.disabled]="isDisabled" (click)="!isDisabled && editClicked($event)"
    class="fa fa-pencil-square-o edit" aria-hidden="true">
  </i>

  <mat-icon svgIcon="trash" class="material-icons delete" matTooltip="Delete" *ngIf="needDelete"
    [class.disabled]="isDisabled" (click)="!isDisabled && deleteClicked($event)" aria-hidden="true">
  </mat-icon>

  <!-- <i  *ngIf="needDelete"  matTooltip="Delete" style="color: #DD0330" (click)="deleteClicked($event)" class="fas fa-trash delete" aria-hidden="true"></i> -->
  <!-- <mat-icon *ngIf="needDelete" (click)="deleteClicked($event)" class="delete" matTooltip="Delete" svgIcon="trash"
    ></mat-icon> -->

</div>