// AUTH
export const EMAIL: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const ONLY_NUMBERS: RegExp = /^\d+$/;
export const ONLY_ALPHABETS: RegExp = /^[a-zA-Z]+$/;
export const ALLOW_ONLY_NUMBERS: RegExp = /^\d+(\.\d{1,2})?$/;
export const PATTERN_FOR_IP_VERSION_4: RegExp = /^((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])$/;
export const PATTERN_FOR_MAC_ADDRESS: RegExp = /\d{2}(?=\d{4})|\d+/g;

// IP Address
export const IPV4: RegExp = /^((25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)\.){3}(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)$/;
export const IPV4RANGE: RegExp = /^((25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)\.){3}(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)-((25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)\.){3}(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)$/

export const IPV4WithOptionalPort: RegExp = /^((25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)\.){3}(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)(:(6553[0-5]|[1-5]?[0-9]{1,4}|[1-9][0-9]{0,3}))?$/;



export const IPV4Sub: RegExp = /^((25[0-5]|2[0-4]\d|1\d{2}|\d{1,2})\.){3}(25[0-5]|2[0-4]\d|1\d{2}|\d{1,2})\/(3[0-2]|[1-2]?\d)$/;;

// PORT

export const PORT = /^(\d|[1-9]\d{1,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/


// url

export const URL_REGEX: RegExp = /^(https?:\/\/)?([\w.-]+)\.[a-zA-Z]{2,}([\/\w\-._~:/?#[\]@!$&'()*+,;=]+)?$/;


// config

export const NAME: RegExp = /^[a-zA-Z][a-zA-Z0-9_.]{0,19}$/;

