import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ElementRef, AfterViewInit, HostListener, ViewChild } from '@angular/core';

@Component({
    selector: 'header-select',
    templateUrl: './header-select.component.html',
    styleUrls: ['./header-select.component.scss'],
})
export class HeaderSelectComponent implements OnChanges, AfterViewInit {
    @Input() selectedOption: string;
    @Input() options: Array<any>;
    @Input() viewKey: string;
    @Input() icon: string;
    @Input() parentSelector: string;
    isDropdownOpen: boolean = false
    @ViewChild('dropdown') dropdownButton: ElementRef
    @ViewChild('dropdownmenu1') dropdownMenu!: ElementRef;
    @Output() optionSelected: EventEmitter<any> = new EventEmitter<any>();

    searchText: string = '';
    constructor(
        private elementRef: ElementRef
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if ('selectedOption' in changes) {
            // if (!changes.selectedOption.currentValue) {
            //   if (this.viewKey) {
            //     this.selectedOption = "Select"
            //   } else {
            //     this.selectedOption[this.viewKey] = "Select"
            //   }
            // }
        }
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent): void {
        if (this.dropdownButton && !this.dropdownButton.nativeElement.contains(event.target) &&
            this.dropdownMenu && !this.dropdownMenu.nativeElement.contains(event.target)) {
            this.isDropdownOpen = false;
        }
    }

    ngAfterViewInit(): void {

        if (this.dropdownButton) {
            this.dropdownButton.nativeElement.addEventListener('click', () => {
                this.isDropdownOpen = !this.isDropdownOpen; // Toggle on button click
            });
        }

        // Observe the dropdown menu for `show` class changes if needed
        if (this.dropdownMenu) {
            const observer = new MutationObserver(() => {
                this.isDropdownOpen = this.dropdownMenu.nativeElement.classList.contains('show');
            });

            observer.observe(this.dropdownMenu.nativeElement, { attributes: true, attributeFilter: ['class'] });
        }
    }

    optionChanged(ev) {
        this.optionSelected.emit(ev);
    }

    filterValue() {
        const filterValue = this.searchText;
        const filterOption = filterValue.toLowerCase();
        const filterArray: NodeListOf<HTMLElement> = document.querySelectorAll(this.parentSelector + ' .dropdown-item');
        for (let i = 0; i < filterArray.length; i++) {
            let filterInstance = filterArray[i];
            if (filterInstance.innerText.toLowerCase().includes(filterOption)) {
                filterInstance.style.display = '';
            } else {
                filterInstance.style.display = 'none';
            }
        }
    }

    clearSearch() {
        this.searchText = '';
        // immedieately invoking the function will conflict with onclick, and dev change wont trigger
        setTimeout(() => {
            this.filterValue(); // to remove "display:none" for all items
        }, 1000);
    }
}
