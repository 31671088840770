import { device } from './../../../modules/organization/components/inventory/modals/addDevice/add.device.dialog';
import { GlobalService } from 'app/shared/services/core/global.service';
import { DashboardConfService } from 'app/modules/dashboard/services/dashboard-conf.service';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { Component, OnInit, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import * as _ from 'lodash';
import { CosgridAppStore } from 'app/store/app.store';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { DeviceListService, CustomerListService } from 'app/shared/services/comp';
import { DeviceList } from 'app/shared/model/device.list.model';
import { CommonService } from 'app/shared/services/core';
import { StatusService } from 'app/modules/sd-wan-gateway/sub-modules/status/services/status.service';
import { skipWhile } from 'rxjs/operators';
import { DeviceSlice } from 'app/store/reducers/devices.reducer';
import { EncryptedLocalStorageService } from 'app/core/services/local-storage.service';

declare var $: any;

@Component({
    moduleId: module.id,
    selector: 'cosgrid-devices-list-dropdown-component',
    templateUrl: 'device.list.dropdown.component.html',
    providers: [DeviceListService],
})
export class DeviceListComponent implements OnInit, AfterViewInit {
    @Output() deviceSelected: EventEmitter<void> = new EventEmitter<void>();
    @ViewChild('dropdown1') dropdownButton: ElementRef
    @ViewChild('dropdownmenu2') dropdownMenu!: ElementRef;
    deviceList: DeviceList[];
    selectedDevice: DeviceList;
    currentTenantId = this.customerListService.getTenantId();
    selectedDeviceName = 'select device';
    dButton;
    isDropdownOpen: boolean = false
    vpnDashboard;
    constructor(
        private deviceListService: DeviceListService,
        private router: Router,
        private customerListService: CustomerListService,
        private encryptedLocalStorageService: EncryptedLocalStorageService,
        private commonservice: CommonService,
        private dashboard: DashboardConfService,
        private statusService: StatusService,
        private appStore: Store<CosgridAppStore>,
        private hotKeyService: HotkeysService,
        private globalService: GlobalService,
        private elementRef: ElementRef
    ) {
        // this.hotKeyService.add(new Hotkey('d',(event:KeyboardEvent):boolean=>{
        //      this.dButton= document.getElementById('dropdownButton');
        //     const search = document.getElementById('search');
        //     this.dButton.click()
        //     search.focus()
        //     return false // to prevent bubbling
        // }))
    }
    ngOnInit() {
        this.subscribeToDeviceSliceFromStore();
        $('#selectdevice').popover('hide');
        this.appStore
            .select((state) => state.settingSlice)
            .subscribe((value) => {
                this.vpnDashboard = value.setting?.vpnDashboard;
            });
        // this.deviceList = this.deviceListService.deviceList;
        try {
            this.selectedDevice = this.encryptedLocalStorageService.getItem('selectedDevice');
            this.selectedDeviceName = this.selectedDevice.name || 'Select Device';
        } catch (err) {
            //
            // this.selectedDeviceName = 'Select Device';
            // $('#selectdevice').popover('show');
            // setTimeout(()=>{
            //     $('#selectdevice').popover('hide');
            // },2000)
        }
    }

    ngAfterViewInit() {
        $('[data-toggle="tooltip"]').tooltip();
        if (this.dropdownButton) {
            this.dropdownButton.nativeElement.addEventListener('click', () => {
                this.isDropdownOpen = !this.isDropdownOpen; // Toggle on button click
            });
        }

        // Observe the dropdown menu for `show` class changes if needed
        if (this.dropdownMenu) {
            const observer = new MutationObserver(() => {
                this.isDropdownOpen = this.dropdownMenu.nativeElement.classList.contains('show');
            });

            observer.observe(this.dropdownMenu.nativeElement, { attributes: true, attributeFilter: ['class'] });
        }
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent): void {
        // Close dropdown if clicked outside
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.isDropdownOpen = false;
        }
    }
    loadDevices() {
        this.deviceListService.getListOfDevices().subscribe((devices: any) => {
            this.deviceListService.dispatchAllDevicesToStore(devices);
        });
        this.subscribeToDeviceSliceFromStore();
    }

    getVpnDashboardValue() {
        this.appStore
            .select((state) => state.settingSlice)
            .subscribe((value) => {
                this.vpnDashboard = value.setting?.vpnDashboard;
            });
    }
    /**
     * This method performs two operations
     *      Populates the dropdown with list of devices from store
     *      Prefill the value from store during navigation back
     */
    //
    subscribeToDeviceSliceFromStore() {
        this.appStore
            .select((slices) => slices.deviceSlice.listOfDevices)
            .pipe(skipWhile((listOfDevices) => _.isEmpty(listOfDevices)))
            .subscribe((listOfDevices: DeviceSlice['listOfDevices']) => {
                if (listOfDevices) {
                    this.deviceList = listOfDevices;
                }
            });
        this.appStore
            .select((slices) => slices.deviceSlice.selectedDevice)
            .pipe(skipWhile((device) => _.isEmpty(device)))
            .subscribe((device: DeviceSlice['selectedDevice']) => {
                if (device.name) {
                    const previous = this.selectedDevice;
                    // his.selectedDeviceName = device.name;
                    this.selectedDevice = device;
                    // this.deviceSelected.emit();

                    if (previous !== device) {
                        //  this.devChanged(device);
                        this.selectedDeviceName = device.name || 'Select Device';
                        this.deviceSelected.emit();
                    }
                } else {
                    this.commonservice.deviceSelected.next(false);
                    this.selectedDeviceName = 'Select Device';
                }
            });
    }
    devChanged(device) {
        // localStorage.setItem('selectedDevice',device)
        // this.getDashboardConf();
        this.getVpnDashboardValue();
        this.selectedDevice = device;

        this.selectedDeviceName = device.name || 'Select Device';
        this.deviceSelected.emit();

        this.deviceListService.dispatchSelectedDeviceToStore(device);

        if (!this.vpnDashboard) {
            this.prefetchGrafanaData().then((res) => {
                const currentUrl = this.router.url;
                // routing to loading and then the url to avoid "not reloading" when navigating to same url bt device changed
                this.router.navigate(['/loading']).then(() => {
                    if (currentUrl.includes('dashboard') || currentUrl.includes('tenant-selection')) {
                        this.router.navigateByUrl('/device/status');
                    } else {
                        this.router.navigateByUrl(currentUrl);
                    }
                    this.notifyBackend();
                });
            });
        } else {
            const currentUrl = this.router.url;
            // routing to loading and then the url to avoid "not reloading" when navigating to same url bt device changed
            this.router.navigate(['/loading']).then(() => {
                if (currentUrl.includes('dashboard') || currentUrl.includes('tenant-selection')) {
                    this.router.navigateByUrl('/device/configure/overlay-mesh-vpn');
                } else {
                    this.router.navigateByUrl(currentUrl);
                }
            });
        }
    }

    prefetchGrafanaData(): Promise<any> {
        const promise = new Promise((resolve, reject) => {
            this.statusService.postGrafanaInterface().subscribe(
                (data: any) => {
                    localStorage.setItem('grafanaInterface', JSON.stringify(data));
                    this.encryptedLocalStorageService.setItem('grafanaInterface', JSON.stringify(data));

                    resolve('resolved');
                },
                (err) => {
                    reject(err);
                },
            );
        });

        return promise;
    }

    notifyBackend() {
        this.statusService.getDeviceStatus().subscribe((res) => { });
    }

    filterValue(e) {
        const filterValue = e.target.value;
        const filterOption = filterValue.toLowerCase();
        const filterArray = document.getElementsByClassName('dropdown-item') as HTMLCollectionOf<HTMLElement>;
        let tempDeviceList = this.deviceList;
        for (let i = 0; i < filterArray.length; i++) {
            if (filterArray[i].innerHTML.toLowerCase().includes(filterOption)) {
                filterArray[i].style.display = '';
            } else {
                filterArray[i].style.display = 'none';
            }
        }
        tempDeviceList = this.deviceList.filter((inp) => inp.name.toLowerCase().includes(filterOption));
        if (e.code === 'Enter') {
            this.devChanged(tempDeviceList[0]); // if enter is pressed choose the first device
            this.dButton.click(); // close the search panel
        }
    }

    toggleDropdown(): void {
        console.log("toggled")
        // const dropdownButton = document.getElementById('dropdownButton');
        // if (dropdownButton) {
        //     return dropdownButton.getAttribute('aria-expanded') === 'true';
        // }
    }
}
