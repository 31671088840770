import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

@Component({
    selector: 'cosgrid-configure-modal-footer',
    templateUrl: './configure-modal-footer.component.html',
    styleUrls: ['./configure-modal-footer.component.scss'],
})
export class ConfigureModalFooterComponent implements OnInit, AfterViewInit {
    constructor(private dialog: MatDialog) { }

    @Input() requestLoading = false;
    @Input() data = false;
    @Input() valid = false;
    @Input() resetText: string = 'Reset'
    @Input() saveChangesText: string = 'Save changes'
    @Input() addText: string = 'Add'

    @Output() add = new EventEmitter<string>();
    @Output() edit = new EventEmitter<string>();
    @Output() close = new EventEmitter<string>();
    // reset command
    @Output() reset = new EventEmitter<string>();

    ngOnInit() { }
    ngAfterViewInit() {
        $('#close').on('click', () => {
            $('#close').popover('toggle');
        });

        $('#yes').on('click', () => { });
    }

    // reset toggle popup
    toggleRPopup(event) {
        event.stopPropagation();
        $('#myRPopup').toggleClass('show');
    }

    togglePopup(event) {
        event.stopPropagation();
        $('#myPopup').toggleClass('show');
    }

    callAdd() {
        this.add.next('call add function');
    }
    callEdit() {
        this.edit.next('edit');
    }
    closeModal() {
        if (this.close.observers.length > 0) {
            // if close has a assigned function, use that orelse use a use closeAll()
            this.close.next('close');
        } else {
            this.dialog.closeAll();
        }
    }

    // reset button edit mode
    resetModal() {
        this.reset.next('reset');
        this.dialog.closeAll();
        // close functionality
        // if (this.close.observers.length > 0) {
        //   this.close.next('close');
        // } else {
        //   this.dialog.closeAll()
        // }
    }
}
