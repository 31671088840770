import { values, includes } from 'lodash';
import { NG_VALUE_ACCESSOR, NgControl, ControlValueAccessor, UntypedFormControl, Validators, FormControl } from '@angular/forms';
import {
    Component,
    OnInit,
    forwardRef,
    Input,
    Injector,
    Optional,
    Self,
    AfterViewInit,
    SimpleChange,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
    ViewChild,

} from '@angular/core';
import { Subscription, debounceTime, pairwise, startWith } from 'rxjs';
import { hasRequiredField } from 'app/shared/services/misc';
import { MatOption } from '@angular/material/core';

@Component({
    selector: 'config-select',
    templateUrl: './config-select.component.html',
    styleUrls: ['./config-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ConfigSelectComponent),
            multi: true,
        },
    ],
})
export class ConfigSelectComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {
    @Input() name: string;
    @Input() className: string = '';
    @Input() options: Array<any> = [];
    @Input() viewKey: string;
    @Input() valueKey: string;
    @Input() dataWithIcon: boolean = false;
    @Input() description;
    @Input() format;
    @Input() note;
    @Input() multiple: boolean = false;
    @Input() disabled: boolean = true;
    @Input() default: string;
    @Input() defaultValue: string = "";
    @Input() placeholder: string;
    @Output() change: EventEmitter<any> = new EventEmitter<any>();
    @Input() needTooltip: boolean = false;
    @Input() tooltipDescription: string;
    @Input() needSearch: boolean = false;
    @Input() needSelectAll: boolean = false;

    onChange: Function;
    onTouched: Function;

    input = new UntypedFormControl(['', [Validators.required]]);
    inputChangeSub: Subscription;
    searchInput = new FormControl('');

    isNgModel: boolean = false;
    isRequired: boolean = false;

    //newchanges
    fullOptions: any;
    isStringArray: boolean;
    selectedOptions: Array<any> = [];
    @ViewChild('allSelected') private allSelected: MatOption

    copyOptions = true


    constructor(
        @Self()
        @Optional()
        private injector: Injector,
    ) { }

    ngOnInit() {
    }

    ngAfterViewInit() {
        const ngControl: NgControl = this.injector.get(NgControl, null);
        if (ngControl) {
            this.input = ngControl.control as UntypedFormControl;
            if (hasRequiredField(this.input)) {
                this.isRequired = true;
            } else {
                this.isRequired = false;
            }
            this.input.updateValueAndValidity();
            if (this.input.value && Array.isArray(this.input.value)) {
                this.selectedOptions = [...this.input.value]
            }

        } else {
            this.isNgModel = true;
            this.subscribeToValueChanges();
        }

        //newchanges
        this.fullOptions = [...this.options];

        if (this.needSearch) {
            this.searchInput.valueChanges
                .subscribe((value: string) => {
                    this.filterOptions(value);
                });
        }
        // if (this.needSelectAll)
        //     this.subscribeSelectAll()

        // this.inputChangeSub = this.input.valueChanges.subscribe((res) => {
        //     this.onChange(res);
        // });


    }

    ngOnChanges(changes: SimpleChanges) {
    }
    subscribeToValueChanges() {
        this.inputChangeSub = this.input.valueChanges.subscribe((res) => {
            this.onChange(res);
        });
    }

    optionChanged(ev) {
        // const currentLength = ev.value
        this.change.emit(ev)
        this.emitSelectionChange(ev)
        if (this.options.length + 1 < this.input.value.length && this.input.value.includes("*")) {
            const updatedValue = this.input.value.filter(option => option !== '*');
            this.input.patchValue(updatedValue);
            this.selectedOptions = updatedValue
        }
        if (this.needSelectAll)
            this.checkSelectAll(ev.value)



    }

    writeValue(value: any): void {
        if (value !== undefined && this.input.value !== value) {
            this.input.setValue(value);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }


    //new changes 
    openedChange(isOpened: boolean): void {
        // console.log('opened change', this.fullOptions, this.input.value, this.options)
        if (!isOpened) {
            // Reset search input when dropdown is closed
            this.searchInput.setValue('');
        }
    }

    selectedAll: boolean = false
    checkSelectAll(value: string[]) {
        if (this.searchInput.value != '')
            return

        // Case 1: Deselect all if all options are selected and "Select All" is not explicitly chosen
        if (value.length == this.options.length && !value.includes("*")) {
            this.selectedOptions = [];
            this.input.patchValue([]);
            this.selectedAll = false;
            return;
        }

        // Case 2: Handle "Select All"
        if (value.includes("*") && value.length <= this.options.length && !this.selectedAll) {
            const updatedValues = [
                ...(this.valueKey ? this.options.map(option => option[this.valueKey]) : this.options),
                "*"
            ]
            this.input.patchValue(updatedValues);
            this.selectedOptions = updatedValues;
            this.selectedAll = true;
            return;
        }

        // Case 3: Remove "Select All" when explicitly deselected
        if (value.includes("*") && value.length <= this.options.length && this.selectedAll) {
            const updatedValue = value.filter(option => option !== '*');
            this.input.patchValue(updatedValue);
            this.selectedOptions = updatedValue
            this.selectedAll = false;
            return;
        }

        // Case 4: Remove "Select All" if any individual option is selected or deselected
        if (value.includes("*") && value.length > 1 && value.length < this.options.length) {
            const updatedValue = value.filter(option => option !== '*');
            this.input.patchValue(updatedValue);
            this.selectedOptions = updatedValue
            this.selectedAll = false;
        }




    }

    filterOptions(searchValue: string): void {
        if (this.copyOptions) {
            this.fullOptions = [...this.options]
            this.copyOptions = false
        }
        // this.fullOptions = [...this.options]
        const search = searchValue?.toLowerCase() || '';
        this.options = this.fullOptions.filter((option) =>
            !this.valueKey
                ? option.toLowerCase().includes(search)
                : option[this.viewKey]?.toLowerCase().includes(search),
        );
    }

    selectionChange(event) {
        if (event.isUserInput && event.source.selected == false) {
            let index = this.selectedOptions.indexOf(event.source.value);
            this.selectedOptions.splice(index, 1);
        }
    }

    emitSelectionChange(event: any): void {

        if (this.input.value && this.input.value.length > 0) {
            this.input.value.forEach((e) => {
                if (this.selectedOptions.indexOf(e) == -1) {
                    this.selectedOptions.push(e);
                }
            });
        }
        if (this.multiple)
            this.input.patchValue(this.selectedOptions);
    }

    // subscribeSelectAll() {
    //     console.log("subscribing")
    //     this.input.valueChanges
    //         .pipe(
    //             startWith([]),
    //             pairwise()
    //         )
    //         .subscribe(([previousValue, currentValue]) => {
    //             console.log(previousValue, currentValue)
    //         });
    // }
}