import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'cosgrid-clipboard-text',
    templateUrl: './clipboard-text.component.html',
    styleUrls: ['./clipboard-text.component.scss'],
})
export class ClipboardTextComponent implements OnInit {
    @Input() value: any;
    copied: boolean = false;

    constructor() { }

    // This is called when the component initializes
    ngOnInit(): void {
        this.transformValue();
    }

    // This is called when an @Input() property changes
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['value']) {
            this.transformValue();
        }
    }

    // Converts array of strings to a single string
    private transformValue() {
        if (Array.isArray(this.value)) {
            this.value = this.value.join('');
        }
    }

    // Handles copy button click and sets copied flag
    copyClick() {
        this.copied = true;
        setTimeout(() => (this.copied = false), 2000);
    }
}
