<a class="selectContainer" [ngClass]="{'clicked': isDropdownOpen}">
    <div class="btn-group d-flex justify-content-around w-100">
        <button class="textButton" type="button" [innerText]="viewKey ? selectedOption[viewKey] : selectedOption"
            data-container="body" data-toggle="popover" data-placement="bottom" data-trigger="focus" tabindex="0">
            Select
            <span class="sr-only"></span>
        </button>
        <button #dropdown type="button" id="dropdownButton" class="dropdown-toggle dropdown-toggle-split clickable"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="sr-only"></span>
        </button>
        <div #dropdownmenu1 class="dropdown-menu dropdown-menu-right overflow DropdownAnimate slideIn"
            style="min-width: 200px;">
            <div class="position-relative mx-2">
                <p class="position-absolute" style="top: .2rem; left: .2rem;">
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"
                        fill="#000000">
                        <path
                            d="M765-144 526-383q-30 22-65.79 34.5-35.79 12.5-76.18 12.5Q284-336 214-406t-70-170q0-100 70-170t170-70q100 0 170 70t70 170.03q0 40.39-12.5 76.18Q599-464 577-434l239 239-51 51ZM384-408q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Z" />
                    </svg>
                </p>
                <input id="search" [(ngModel)]="searchText" autocomplete="off" style="padding-left: 1.5rem;"
                    (keyup)="filterValue()" (blur)="clearSearch()" type="text" />
            </div>

            <a *ngFor="let option of options; let i = index" (click)="optionChanged(option)" class="dropdown-item">
                <div class="optionsContainer">
                    <span>{{ viewKey ? option[viewKey] : option }}</span>
                    <!-- Only used for select device dropdown -->
                    <cosgrid-table-icon *ngIf="option.deviceStatus === 'running'" [icon]="'positive'"
                        [tooltip]="'Running'"></cosgrid-table-icon>
                    <cosgrid-table-icon *ngIf="option.deviceStatus === 'error'" [icon]="'negative'"
                        [tooltip]="'Not Reachable'"></cosgrid-table-icon>
                    <cosgrid-table-icon *ngIf="option.deviceStatus === 'registered'" [icon]="'registered'"
                        [tooltip]="'Registered'"></cosgrid-table-icon>
                </div>
            </a>
        </div>
    </div>
</a>