import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { CustomerListService } from 'app/shared/services/comp/customer.list.service';
import { EditViewIssueComponent } from './edit-view-issue/edit-view-issue.component';
import { SupportService, AlertService } from 'app/shared/services/misc';
import { GlobalService } from 'app/shared/services/core';
import { IRowConfig, tableProps } from 'app/shared/services/comp';
import { TimeformatPipe } from 'app/shared/pipes/timeformat.pipe';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FollowUpComponent } from 'app/core/components/header/components/support/components/track-ticket-status/follow-up/follow-up.component';
import * as moment from 'moment';
@Component({
    selector: 'cosgrid-track-ticket-status',
    templateUrl: './track-ticket-status.component.html',
    styleUrls: ['./track-ticket-status.component.scss'],
})
export class TrackTicketStatusComponent implements OnInit {
    rows: any = []
    rowProps: IRowConfig[] = [
        // { header: 'Ticket ID', key: 'issue_id', type: 'string' },
        { header: 'Product', key: 'product', type: 'string' },
        { header: 'Issue Name', key: 'title', type: 'string' },
        { header: 'Devices', key: 'device', type: 'array' },
        { header: 'Description', key: 'description', type: 'string' },
        {
            header: 'Priority', key: 'priority_display', type: 'string', dataStyle: (row, key) => ({
                'background-color':
                    row[key].toLowerCase() === 'critical'
                        ? '#ff560b' :
                        row[key].toLowerCase() === 'high'
                            ? '#ffa608' : row[key].toLowerCase() === 'normal'
                                ? '#0078be' : row[key].toLowerCase() === 'low'
                                    ? '#6801af' : '#a9ce21',
                padding: '5px 10px',
                'border-radius': '5px',
                color: '#fff',
                width: '60px',
                display: 'flex',
                'justify-content': 'center',
                'align-items': 'center',
                'font-size': '14px',
            }), headAlign: 'center'
        },
        {
            header: 'Status', headAlign: 'center', key: 'status_display', dataStyle: (row, key) => ({
                'background-color':
                    row[key].toLowerCase() === 'open'
                        ? '#34b000' :
                        row[key].toLowerCase() === 'closed'
                            ? '#ff560b' : row[key].toLowerCase() === 'resolved'
                                ? '#0078be' : row[key].toLowerCase() === 'reopened'
                                    ? '#6801af' : '#ffa608',
                padding: '5px 10px',
                'border-radius': '5px',
                color: '#fff',
                width: '60px',
                display: 'flex',
                'justify-content': 'center',
                'align-items': 'center',
                'font-size': '14px',
            }), type: 'string'
        },
        { header: "Submitted By", key: 'submitter_email', type: 'string' },
        { header: "created at", key: 'createdAt', type: 'string' }
    ]
    tableProps: tableProps = {
        sort: false,
        refresh: false,
        download: false,
        search: false
    }
    issuesData;
    loading: boolean = true;
    currentTenantName: string = this.customerListService.getTenantName();
    xAxisTimestamp: 'seconds' | 'minutes' | 'hours' | 'days' | 'weeks' | 'months' | 'years' = 'days';
    filters: FormGroup;

    constructor(
        private supportService: SupportService,
        private customerListService: CustomerListService,
        private global: GlobalService,
        private alert: AlertService,
        private dialog: MatDialog,
        private timeFormatPipe: TimeformatPipe,
        private fb: FormBuilder,
    ) {
        this.filters = this.fb.group({
            status: '',
            priority: '',
            start_date: moment().subtract(1, 'month').utc().format(),
            end_date: moment().utc().format()
        })

        this.filters.get('start_date')?.valueChanges.subscribe(value => {
            if (value) {
                this.filters.patchValue({ start_date: moment(value).utc().format() }, { emitEvent: false });
            }
        });

        this.filters.get('end_date')?.valueChanges.subscribe(value => {
            if (value) {
                this.filters.patchValue({ end_date: moment(value).utc().format() }, { emitEvent: false });
            }
        });
    }
    pieCharts = {
        status: { data: [], label: [] },
        priority: { data: [], label: [] }
    }

    barChart = {
        dateWise: { timestamp: [], value: [] }
    }

    isSuperAdmin: boolean = false;

    topCards = [
        {
            icon: 'assets/icons/support/open.png',
            title: "Opened",
            value: 0,
            width: '2.3rem',
            height: '2.3rem'
        },
        {
            icon: 'assets/icons/support/closed.png',
            title: "Closed",
            value: 0,
            width: '2.3rem',
            height: '2.3rem'
        },
        {
            icon: 'assets/icons/support/Critical.png',
            title: "Critical",
            value: 0,
            width: '2rem',
            height: '2rem'
        },
        {
            icon: 'assets/icons/support/high.png',
            title: "High",
            value: 0,
            width: '2rem',
            height: '2rem'
        },
        {
            icon: 'assets/icons/support/normal.png',
            title: "Normal",
            value: 0,
            width: '2.2rem',
            height: '2.2rem'
        },
        {
            icon: 'assets/icons/support/low.png',
            title: "Low",
            value: 0,
            width: '2.3rem',
            height: '2.3rem'
        },
        {
            icon: 'assets/icons/support/very-low.png',
            title: "Very Low",
            value: 0,
            width: '2.3rem',
            height: '2.3rem'
        }
    ]

    ngOnInit() {
        this.getIssues();
        this.isSuperAdmin = this.global.isSuperAdmin();
    }
    addEvent(a, b) {
        console.log(this.filters.value);

    }

    onoff(item) {
        item.enabled = !item.enabled;

        if (item.enabled) {
            item.status = 'OPEN';
        } else {
            item.status = 'CLOSE';
        }

        item['tenant_name'] = this.currentTenantName;
        this.supportService.changeIssueStatus(item).subscribe(
            (res: any) => {
                if (res.status === 'OPEN') this.alert.swalSuccess('Successfully opened an issue ');
                else {
                    this.alert.swalSuccess('Successfully closed an issue');
                }
                this.getIssues();
            },
            (err) => { },
        );
    }

    clearDates() {
        this.filters.get('start_date')?.reset();
        this.filters.get('end_date')?.reset();
    }

    openDialog(data) {
        const dialogRef = this.dialog.open(EditViewIssueComponent, {
            data: data,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.getIssues();
            }
        });
    }

    getIssues() {
        this.loading = true;
        this.supportService.getTickets(this.filters.value).subscribe(
            (res: any) => {
                // this.rows = res.filter(row => { return row.issue_id != '461821ea-c197-4f30-afc7-7ac65abc2efa' });
                this.rows = res.tickets
                this.rows.map((row: any) => {
                    row.createdAt = this.timeFormatPipe.transform(row.created, 'DD/MM/yyyy hh:mm')
                    row.description = row.description.split('').length > 20 ? row.description.substr(0, 15) + '...' : row.description
                })
                this.topCards[0].value = res.report.status_count['Open'] < 10 ? `0${res.report.status_count['Open']}` : res.report.status_count['Open']
                this.topCards[1].value = res.report.status_count['Closed'] < 10 ? `0${res.report.status_count['Closed']}` : res.report.status_count['Closed']
                this.makePiechart('status', ['Open', 'Closed'], [res.report.status_count['Open'], res.report.status_count['Closed']])
                this.makePiechart('priority', ['Critical', 'High', 'Normal', 'Low', 'Very Low'], [res.report.priority_count['Critical'], res.report.priority_count['High'], res.report.priority_count['Normal'], res.report.priority_count['Low'], res.report.priority_count['Very Low']])
                this.topCards[2].value = res.report.priority_count['Critical'] < 10 ? `0${res.report.priority_count['Critical']}` : res.report.priority_count['Critical']
                this.topCards[3].value = res.report.priority_count['High'] < 10 ? `0${res.report.priority_count['High']}` : res.report.priority_count['High']
                this.topCards[4].value = res.report.priority_count['Normal'] < 10 ? `0${res.report.priority_count['Normal']}` : res.report.priority_count['Normal']
                this.topCards[5].value = res.report.priority_count['Low'] < 10 ? `0${res.report.priority_count['Low']}` : res.report.priority_count['Low']
                this.topCards[6].value = res.report.priority_count['Very Low'] < 10 ? `0${res.report.priority_count['Very Low']}` : res.report.priority_count['Very Low']
                this.barChartData(res.report.ticket_count_per_day, 'dateWise')
                this.loading = false;
            },
            (err) => {
                this.loading = false;
            },
        );
    }
    makePiechart(title: string, keys: Array<string>, values: Array<number>) {
        let data = []
        let label = []
        keys.forEach((key: string, index: number) => {
            const value = values[index];
            if (value !== 0) {
                data.push({ name: key, value });
                label.push(key);
            }
        });
        this.pieCharts[title] = { data: data, label: label }
    }
    barChartData(data: Array<any>, key: string) {
        if (data == null || data.length <= 0)
            return
        let values: any[] = [];
        let timestamp = [];
        let keys = Object.keys(data[0])

        if (data[0][keys[0]] != this.filters.value.start_date) {
            timestamp.push(String(this.filters.value.start_date))
            values.push(Number(0))
        }
        data.forEach((val, index) => {
            timestamp.push(String(val[keys[0]]));
            values.push(Number(val[keys[1]]));
        })
        if (data[data.length - 1][keys[0]] != this.filters.value.end_date) {
            timestamp.push(String(this.filters.value.end_date))
            values.push(Number(0))
        }

        // for (let i = 0; i < orgValues[0].length; i++) {
        //     timestamp.push(String(orgValues[0][i][0]));
        // }
        // for (let i = 0; i < orgValues.length; i++) {
        //     let data = [];
        //     for (let j = 0; j < orgValues[i].length; j++) {
        //         data.push(Number(orgValues[i][j][1]));
        //     }
        //     val.push(data);
        // }


        this.barChart[key] = { timestamp: timestamp, value: [values] }
        console.log(this.barChart[key]);
    }

    viewFollowUp(event: any) {
        console.log(event);
        this.dialog.open(FollowUpComponent, { data: event.id })

    }
}
